<template>
    <v-container>
        <v-row justify="center" class="my-5">
            <v-col cols="12" md="8">
                <v-card class="elevation-3 rounded-lg">
                    <v-card-title class="headline primary white--text py-4">
                        Tarjeta Física de Rebancarización
                    </v-card-title>
                    <v-card-text>
                        <v-row align="center" class="mt-4">
                            <v-col cols="12" md="4" class="text-center">
                                <v-avatar size="100" color="secondary" class="mb-3">
                                    <v-icon dark x-large>mdi-credit-card</v-icon>
                                </v-avatar>
                                <h3 class="font-weight-bold">Tarjeta Física</h3>
                            </v-col>
                            <v-col cols="12" md="8">
                                <p class="subtitle-1">
                                    Recibe tu Tarjeta de Rebancarización en tu domicilio por un costo de $40.000.
                                    Perfecta para todas tus compras en tiendas físicas y en pasarelas digitales.
                                </p>
                                <v-divider class="my-3"></v-divider>
                                <h4 class="font-weight-bold mb-2">Ventajas:</h4>
                                <ul class="advantages-list pa-0 ml-4">
                                    <li v-for="(item, index) in advantages" :key="index"
                                        class="d-flex align-center mb-1">
                                        <v-icon small color="success" class="mr-2">mdi-check-circle</v-icon>
                                        <span class="body-2">{{ item }}</span>
                                    </li>
                                </ul>
                                <v-alert dense text type="info" color="primary" class="mt-4">
                                    Ten listo tu soporte de ingresos (no mayor a 30 días) y tu cédula para el siguiente
                                    paso con tu Tarjeta de Rebancarización.
                                </v-alert>
                            </v-col>
                        </v-row>
                        <v-divider class="my-4"></v-divider>
                        <v-row align="center" class="mt-4">
                            <v-col cols="12" md="4" class="text-center">
                                <h4 class="font-weight-bold">¿Tienes un cupón?</h4>
                            </v-col>
                            <v-col cols="12" md="5">
                                <v-text-field v-model="couponCode" label="Código de cupón" outlined dense hide-details
                                    append-icon="mdi-ticket-percent"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="3">
                                <v-btn color="secondary" block @click="applyCoupon" :disabled="!couponCode">
                                    Aplicar Cupón
                                </v-btn>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-center mt-3">
                                <v-alert v-if="promoMessage" :type="promoValid ? 'success' : 'error'" dense text
                                    prominent border="left" class="ma-0">
                                    {{ promoMessage }}
                                </v-alert>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="justify-center pa-4">
                        <v-btn v-if="!isFree" color="primary" x-large class="px-8" @click="openCheckout">
                            Pagar ${{ amountToPay }}
                        </v-btn>
                        <v-btn v-else color="success" x-large class="px-8" @click="nextStep">
                            Obtener mi TDR
                        </v-btn>
                        <v-btn text color="grey darken-1" class="ml-4" @click="goBack">
                            Cancelar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { HTTP } from '../../services/http-common';

export default {
    data() {
        return {
            couponCode: '',
            promoMessage: '',
            isFree: false,
            amountToPay: 40000,
            promoValid: false,
            advantages: [
                'Puedes usarla en tiendas físicas',
                'Úsala en comercios en línea y en pasarelas digitales',
                'Cupo hasta de $800.000',
                'Compras a 1 cuota sin intereses',
                'Habilitada para uso nacional e internacional',
                'Plazos de 1-36 meses para compras nacionales',
                'Consumos en el exterior diferidos automáticamente a 24 meses',
                'Tasa de interés competitiva',
                'Acceso a beneficios de la marca VISA',
            ]
        }
    },
    props: {
        identification_number: {
            type: String,
            required: true,
        },
        external_id: {
            type: String,
            required: true,
        },
        quota: {
            type: String,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            epaycoData: 'epayco/epaycoData',
            epaycoError: 'epayco/epaycoError',
            epaycoErrorMessage: 'epayco/epaycoErrorMessage',
        }),
    },
    methods: {
        ...mapActions('epayco',['getEpaycoData']),

        async applyCoupon() {
            if (!this.couponCode) {
                this.promoMessage = '';
                this.promoValid = false;
                return;
            }

            try {
                const response = await HTTP.post('/miQnt/Pay/verify-coupon-and-create-application/', {
                    external_id: this.external_id,
                    coupon_code: this.couponCode,
                    quota: this.quota,
                });
                if (response.data.valid) {
                    this.promoMessage = 'Código promocional aplicado con éxito.';
                    this.promoValid = true;
                    this.discount = response.data.discount;
                    this.discountType = response.data.type;
                    this.applyDiscount(response.data.discount, response.data.type);
                } else {
                    this.promoMessage = response.data.message;
                    this.promoValid = false;
                }
            } catch (error) {
                this.promoMessage = 'Código promocional no válido o expirado.';
                this.promoValid = false;
            }
        },
        applyDiscount(discount, type) {
            const configValue = 40000; // Valor de la TDR, puede ser dinámico #TODO
            let discountValue = 0;

            if (type === 'percentage') {
                discountValue = configValue * (discount / 100);
            } else if (type === 'amount') {
                discountValue = discount;
            }

            this.amountToPay = Math.max(configValue - discountValue, 0);
            this.isFree = this.amountToPay === 0;
        },

        async checkPaymentStatus(invoice_id, handler) {
            const maxRetries = 60;
            const delay = 6000;
            let attempts = 0;

            let numeroIdentificacion = this.identification_number
            let external_id = this.external_id
            let quota = this.quota
            const checkStatus = async () => {
                try {
                    const checkResponse = await HTTP.get(`/miQnt/Pay/check-payment-status/${invoice_id}/${numeroIdentificacion}/${external_id}/${quota}`);
                    if (checkResponse.status === 200) {
                        this.transactionState = '1'; // Estado aceptado
                        this.transactionData = checkResponse.data;
                        handler.close(); // Cerrar el modal de ePayco

                    } else if (checkResponse.data.message === 'Transacción pendiente' && attempts < maxRetries) {
                        this.transactionState = '3'; // Estado pendiente
                        this.transactionData = checkResponse.data;
                        attempts++;
                        setTimeout(checkStatus, delay);
                    } else {
                        this.transactionState = '2'; // Estado rechazado
                        this.transactionData = checkResponse.data;
                        console.error('Error checking payment status:', checkResponse.data.message);
                    }
                } catch (error) {
                    if (attempts < maxRetries) {
                        attempts++;
                        setTimeout(checkStatus, delay);
                    } else {
                        this.transactionState = '2'; // Estado rechazado
                        this.transactionData = { message: error.message };
                        console.error('Error checking payment status:', error);
                    }
                }
            };

            setTimeout(checkStatus, delay);
        },

        nextStep() {
            this.$router.go();
        },

        async openCheckout() {
            try {
                const config = this.epaycoData;

                if (!config.error) {
                    var handler = ePayco.checkout.configure({
                        key: config.public_key,
                        test: false
                    });

                    var data = {
                        name: config.name,
                        description: config.description,
                        invoice: config.invoice_id,
                        currency: "cop",
                        amount: this.amountToPay,
                        tax_base: '',
                        tax: config.tax,
                        tax_ico: config.tax,
                        country: "co",
                        lang: "es",
                        external: "false",
                        extra1: config.invoice_id,
                        extra2: config.valor_tdr,
                        extra3: this.external_id,
                        extra4: this.quota,
                        confirmation: "https://miqntapi.qnt.com.co/miQnt/Pay/confirmation/",
                        response: "https://mi.qnt.com.co/tarjeta-credito/solicitud",
                        name_billing: "",
                        address_billing: "",
                        type_doc_billing: "cc",
                        mobilephone_billing: "",
                        number_doc_billing: "",
                        email_billing: "",
                        methodsDisable: ""
                    };

                    handler.open(data);
                    localStorage.setItem("invoice", config.invoice_id)
                    this.checkPaymentStatus(config.invoice_id, handler);
                } else {
                    console.error('Error fetching payment config:', config.error);
                }
            } catch (error) {
                console.error('Error fetching payment config:', error);
            }
        },

        goBack() {
            this.$router.push({ name: 'index' }); // Cambia 'index' por el nombre de la ruta anterior
        }
    },
    created() {
        this.getEpaycoData();
    }
}
</script>

<style scoped>
.v-card-title {
    font-size: 24px;
    font-weight: bold;
    color: #575962;
}

.v-card-title .subtitle-1 {
    font-size: 16px;
    color: #757575;
    margin-top: 8px;
}

.v-card-text p {
    font-size: 16px;
    margin-bottom: 10px;
}

.v-card-text ul {
    padding-left: 20px;
    margin-top: 10px;
    list-style-type: disc;
}

.advantages-list {
    padding-left: 20px;
    margin-top: 10px;
    list-style-type: disc;
}

.v-card-actions {
    padding-bottom: 16px;
}

.advantages-list {
    list-style-type: none;
}

.advantages-list li {
    line-height: 1.2;
}
</style>
